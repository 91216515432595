import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { cloneDeep, set, sortBy, unionBy } from 'lodash';

import { EventService } from './event.service';
import {
  AppFilter,
  AppTable,
  Currency,
  ExchangeRate,
  FilterSelectOption,
  GroupAppFilter,
  JapiQuery,
  JapiTableFilter,
  SortDirectionType
} from '../_models/models';
import { ApiService } from './api.service';
import { AbstractControl, FormGroup, UntypedFormArray, ValidationErrors } from '@angular/forms';
import dayjs from 'dayjs';
import { CreateQueryParams } from '@libs/crud-request-nestjs';
import { SFieldOperator } from '@libs/crud-request-nestjs/dist/types/request-query.types';
import {SharedStoreService} from './shared-store.service';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';
import { cloneDate, SingleValue } from 'ng-zorro-antd/core/time';

@Injectable({providedIn: 'root'})
export class SharedService {
  private renderer: Renderer2;

  constructor(
    private router: Router,
    private api: ApiService,
    private eventService: EventService,
    private notificationService: NzNotificationService,
    private sharedStoreService: SharedStoreService,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // This workaround fixes the bug where the nz-date-picker with time-picker ignores the first change if it's only a time-picker change.
  // GitHub issue: https://github.com/NG-ZORRO/ng-zorro-antd/issues/7994 (they claimed it was fixed, but it is clearly still not working
  // for us and for other people too).
  // We basically clone the current date that was initially patched into the component, and re-setValue again,
  // which triggers the form change.
  // This fix runs only when the picker opens for the first time, it sets a class on the nz-date-picker element to mark that the workaround
  // was done.
  onDatePickerToggle(isOpen: boolean, component: NzDatePickerComponent): void {
    const classFlag = 'workaround-done';
    const nativeEl: HTMLElement = component.origin.elementRef.nativeElement;
    if (isOpen && !nativeEl.classList.contains(classFlag)) {
      const val = cloneDate(component.datePickerService.value as SingleValue);
      component.datePickerService.setValue(val);
      this.renderer.addClass(nativeEl, classFlag);
    }
  }

  allowedCharactersValidator = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const allowedCharacters = /^[a-zA-Z0-9-]*$/;
    const isValid = allowedCharacters.test(control.value);
    return isValid ? null : {invalidCharacters: 'Only letters, numbers, and dashes are allowed'};
  };

  minimumSupplyMarginValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === '') {
      return null;
    }
    if (value < 0 || value > 100) {
      return { marginRange: 'This field must be between 0-100%' };
    }
    return null;
  };

  onHttpCompletionResolveRelatedRoute(
    isSuccess: boolean,
    notificationTitle: string,
    notificationContent: string,
    levelBack: number,
    eventType: string,
    navigationExtras?: NavigationExtras
  ): void {
    const newRoute = this.router.url.split('/').slice(1, -levelBack).join('/');
    this.onHttpCompletionResolve(
      isSuccess,
      notificationTitle,
      notificationContent,
      newRoute,
      eventType,
      navigationExtras
    );
  }

  onHttpCompletionResolve(
    isSuccess: boolean,
    notificationTitle: string,
    notificationContent: string,
    newRoute: string,
    eventType: string,
    navigationExtras?: NavigationExtras
  ): void {
    this.showNotification(isSuccess ? 'success' : 'error', notificationTitle, notificationContent);
    if (newRoute) {
      this.router.navigate([newRoute], navigationExtras ? navigationExtras : {});
    }
    if (eventType) {
      this.eventService.emitEvent(eventType);
    }
  }

  showNotification(notificationType: string, title: string = '', content: string = '') {
    this.notificationService[notificationType](title, content);
  }

  filterClientSideTable(filters: AppFilter[], data: any): any[] {
    const filterFunc = (dataRow) => {
      // need to show only rows that fit all filters selections with AND logic.
      const filtersWithSelectedValuesCount = filters.filter(f => f.selectedValues?.length > 0).length;
      let trueCounter = 0;
      let multiProps;
      let hasMatch = false;
      filters.forEach((filter: AppFilter) => {
        if (filter.selectedValues && filter.selectedValues?.length > 0) {
          hasMatch = false;
          if (filter.serverSearchProps && !dataRow[filter.prop]) {
            multiProps = Object.values(filter.serverSearchProps);
            for (const prop of multiProps) {
              if (dataRow[prop] &&
                dataRow[prop].toString().toLowerCase().includes(filter.selectedValues.toString().toLowerCase())) {
                hasMatch = true;
                break;
              }
            }
            if (hasMatch) {
              trueCounter = trueCounter + 1;
            }
          }

          if (!filter.serverSearchProps && dataRow[filter.prop] &&
            dataRow[filter.prop].toString().toLowerCase().includes(filter.selectedValues.toString().toLowerCase())) {
            trueCounter = trueCounter + 1;
          }
        }
      });
      return trueCounter === filtersWithSelectedValuesCount;
    };
    return data.filter(item => filterFunc(item));
  }

  // TODO remove and use buildQueryTable
  japiTableQuery(page: { number: number; size: number } | null, sort: any, tableFilters: JapiTableFilter[]): JapiQuery {
    const query: JapiQuery = {
      sorting: {
        sortingField: sort.sortingField,
        sortDirection: sort.sortDirection
      },
      filter: {
        filters: []
      },
    };

    // in case api has the ability to return all data (no paging) --> page = null
    if (page !== null) {
      query['paging'] = {
        number: page.number,
        size: page.size
      };
    }

    // build filters
    tableFilters.forEach((tableFilter: JapiTableFilter) => {
      query.filter.filters.push({
        fieldName: tableFilter.fieldName,
        operation: tableFilter.operation,
        value: tableFilter.value
      });
    });

    return query;

  }

  japiTableQueryWithTrashed(page: { number: number; size: number } | null, sort: any, tableFilters: JapiTableFilter[]) {
    const query: any = this.japiTableQuery(page, sort, tableFilters);
    query.filter.withTrashed = true;
    return query;
  }

  getFilterByIdQuery(id: number) {
    return {filter: {id: id}};
  }

  mapJapiQueryToCreateQueryParams(query: JapiQuery): CreateQueryParams {
    const createQueryParams: CreateQueryParams = {};

    if (query.filter) {
      if (query.filter.filters) {
        query.filter.filters.forEach(f => {
          createQueryParams.search ??= {};
          let sField: SFieldOperator;
          if (['EQUALS', 'IN'].some(operator => f.operation === operator)) {
            sField = {$eq: f.value};
          }
          if (f.operation === 'BETWEEN') {
            sField = {$between: this.fixDateByEstTimeZone(f.value as [string, string])};
          }
          createQueryParams.search[f.fieldName] = sField;
        });
      }

      if (query.filter.id) {
        createQueryParams.search['id'] = {$eq: query.filter.id};
      }

      if (query.filter.withTrashed) {
        createQueryParams.includeDeleted = 1;
      }
    }

    if (query.paging) {
      createQueryParams.page = query.paging.number;
      createQueryParams.limit = query.paging.size;
    }

    if (query.sorting) {
      createQueryParams.sort = {
        field: query.sorting.sortingField,
        order: query.sorting.sortDirection,
      };
    }

    return createQueryParams;
  }

  buildTableQuery(tableFilters: AppFilter[], table: AppTable, includes: string[] = []): JapiQuery {
    let needToAddFilter = true;
    let tmpIndex;
    let query: JapiQuery = {};

    if (table && table.sortBy) {
      query = {
        ...query,
        sorting: {
          sortDirection: table.sortDirection.toUpperCase() as SortDirectionType,
          sortingField: table.sortBy
        }
      };
    }

    if (table.isPaginated) {
      query = {
        ...query,
        paging: {
          number: table.pageIndex,
          size: table.pageSize
        }
      };
    }
    if (includes.length > 0) {
      query = {
        ...query,
        include: [...includes]
      };
    }
    tableFilters.forEach((tableFilter: AppFilter) => {
      // build filters
      let filter: JapiTableFilter;
      let operation;
      let selectedValues;
      needToAddFilter = true;
      if (tableFilter.selectedValues && tableFilter.selectedValues.length) {
        if (!query.filter) {
          query = {...query, filter: {filters: []}};
        }
        switch (tableFilter.type) {
          case 'DATES_RANGE':
            filter = {
              fieldName: tableFilter.prop,
              operation: 'BETWEEN',
              value: tableFilter.selectedValues.map(d => dayjs(d).format('YYYY-MM-DD'))
            };
            break;
          case 'SEARCH':
            //   // need to search by id or by name, not supported in api, to workaround check if search value is a number
            const isString = isNaN(+tableFilter.selectedValues) || !tableFilter.serverSearchProps.idProp;
            filter = {
              fieldName: isString ? tableFilter.serverSearchProps.nameProp : tableFilter.serverSearchProps.idProp,
              operation: isString ? 'LIKE' : 'EQUALS',
              value: tableFilter.selectedValues
            };
            break;
          case 'SEARCH_DEAL':
            // special use case for deals table search - the search by id is actually by string that is external id,
            // that has a regex pattern
            const dealIdRegex = new RegExp(/^(\d+-?)+\d+$/);
            filter = {
              fieldName: dealIdRegex.test(tableFilter.selectedValues) ?
                tableFilter.serverSearchProps.idProp : tableFilter.serverSearchProps.nameProp,
              operation: 'LIKE',
              value: tableFilter.selectedValues
            };
            break;
          case 'SEARCH_STRING':
            filter = {
              fieldName: tableFilter.serverSearchProps.nameProp,
              operation: 'LIKE',
              value: tableFilter.selectedValues
            };
            break;
          case 'SEARCH_ID':
            filter = {
              fieldName: tableFilter.serverSearchProps.idProp,
              operation: 'EQUALS',
              value: tableFilter.selectedValues
            };
            break;
          case 'SELECT':
          case 'SELECT_WITH_GROUPS':
            operation = (tableFilter.selectMode === 'multiple' || tableFilter.selectMode === 'tags') ? 'IN' : 'EQUALS';
            selectedValues = operation === 'IN' ?
              tableFilter.selectedValues.map(item => item.value || item) : tableFilter.selectedValues.map(item => item.value || item)[0];
            filter = {
              fieldName: tableFilter.prop,
              operation: operation,
              value: selectedValues
            };

            // special cases
            if (tableFilter.id === 'publishersStatusFilter' && tableFilter.selectedValues.map(item => item.value).includes('DELETED')) {
              const deletedAtFilter: JapiTableFilter = {fieldName: 'deletedAt', operation: 'IS_NOT_NULL', value: ''};
              if (!query.filter) {
                query = {...query, filter: {filters: []}};
              }
              query.filter.filters.push(deletedAtFilter);
              query.filter.withTrashed = true;

              // // if deleted was only filter - remove the filter- because we use withTrashed boolean instead of filters
              if (tableFilter.selectedValues.length === 1) {
                needToAddFilter = false;
              } else {
                //   // >1
                tmpIndex = (filter.value as any[]).indexOf('DELETED');
                if (tmpIndex > -1) {
                  (filter.value as any[]).splice(tmpIndex, 1);
                }
              }
            }
            break;
          default:
            break;
        }
        if (needToAddFilter && filter) {
          query.filter.filters.push(filter);
        }
      }
    }
    );
    return query;
  }

  getQueryParamsFromAppFilters(filters: AppFilter[]): { [key: string]: string } {
    const queryParams = {};
    filters.forEach(filter => {
      if (filter.sendParamAs === 'query') {
        let val = filter.selectedValues;
        if (Array.isArray(val)) {
          if (typeof val[0] === 'object') {
            val = val.map(item => item.value).join();
          } else {
            val = val.join();
          }
        }
        set(queryParams, filter.serverSearchProps['nameProp'], val || '');
      }
    });
    return queryParams;
  }

  updateFilter(filters: AppFilter[], filterId: string, updateProp: string, newValue: any) {
    const localFilters = cloneDeep(filters);
    const filterIndex = localFilters.findIndex(filter => filter.id === filterId);
    localFilters[filterIndex][updateProp] = newValue;
    return localFilters;
  }

  getFilterItemIndex(filters: AppFilter[], filterId: string) {
    const filterIndex = filters.findIndex(filter => filter.id === filterId);
    return filterIndex;
  }

  stringArrayToFilterValue(val: string[], sortByDisplayName: boolean = false): FilterSelectOption[] {
    const stringFilter = val.map(str => ({id: str, value: str, displayName: str}));
    if (sortByDisplayName) {
      stringFilter.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }
    return stringFilter;
  }

  keyValueToFilterValue(val: { [key: number | string]: string }, sortByDisplayName: boolean = false): FilterSelectOption[] {
    const keyValueFilter = Object.keys(val).map(key => ({id: key, value: key, displayName: val[key]}));
    if (sortByDisplayName) {
      keyValueFilter.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }
    return keyValueFilter;
  }

  updateFilterPossibleValues(
    filters: AppFilter[],
    filterId: string,
    possibleValues: any[],
    isSkipSort: boolean = false
  ): AppFilter[] {
    const localFilters = cloneDeep(filters);
    const updateFilterIndex = localFilters.findIndex(filter => filter.id === filterId);
    // add already selected values to possible in order to show them in nz-select element
    const selectedValues = localFilters[updateFilterIndex].selectedValues;
    possibleValues = unionBy(possibleValues, selectedValues, 'value');
    if (!isSkipSort) {
      possibleValues = sortBy(possibleValues, 'displayName');
    }
    localFilters[updateFilterIndex].possibleValues = [...possibleValues];
    localFilters[updateFilterIndex].isLoading = false;
    return localFilters;
  }

  // deprecated because may cause error in @Input comparison in AppFilter component
  // instead added isHidden property for AppFilter class and mutate it with updateFilter method here
  // removeFilterFromFilters(filters: AppFilter[], filterId: string) {
  //   const filterIndex = filters.findIndex(filter => filter.id === filterId);
  //   filters.splice(filterIndex, 1);
  //   return cloneDeep(filters);
  // }

  areFiltersValid(filters: AppFilter[]) {
    const filterIndex = filters.findIndex(filter => filter.isValid === false);
    return filterIndex === -1;
  }

  areFiltersReady(filters: AppFilter[]) {
    const filterIndex = filters.findIndex(filter => filter.isReady === false && !filter.isHidden);
    return filterIndex === -1;
  }

  getFilterSelectedValues(filters: AppFilter[], filterId: string) {
    const filterIndex = filters.findIndex(filter => filter.id === filterId);
    return filters[filterIndex].selectedValues;
  }

  clearSelectedValues(filters: AppFilter[], filterId: string) {
    const localFilters = cloneDeep(filters);
    const filterIndex = localFilters.findIndex(filter => filter.id === filterId);
    localFilters[filterIndex].selectedValues = [];
    return localFilters;
  }

  resetFilterSelectedValues(filters: AppFilter[]) {
    const localFilters = cloneDeep(filters);
    localFilters.forEach(filter => {
      filter.selectedValues = [];
      filter.isValid = true;
      if (filter.defaultValues) {
        filter.selectedValues = cloneDeep(filter.defaultValues);
      }
    });
    return localFilters;
  }

  removeFromArrayOfObjectsByProp(arr: any[], prop: string, values: string[]) {
    const newArr = arr.reduce((res, val) => {
      if (!values.includes(val[prop])) {
        res.push(val);
      }
      return res;
    }, []);
    return newArr;
  }

  swapArrayElements = (arr, toIndex, fromIndex) => {
    if (arr.length === 1) {
      return arr;
    }
    arr.splice(fromIndex, 1, arr.splice(toIndex, 1, arr[fromIndex])[0]);
    // [arr[toIndex], arr[fromIndex]] = [arr[fromIndex], arr[toIndex]];
    return cloneDeep(arr);
  };

  moveArrayItemToIndex = (arr, toIndex, fromIndex) => {
    if (arr.length === 1) {
      return arr;
    }
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
    return cloneDeep(arr);
  };

  getExchangeRate(date?: string): Observable<ExchangeRate[]>  {
    return this.api.getExchangeRate(date).pipe(
      catchError(err => {
        this.onHttpCompletionResolve(false, 'Failure', 'Currencies fetch failed',
          '', '');
        return of(err);
      }),
    );
  }

  getCurrencyFromLocalStorage(): Observable<Currency> {
    return this.sharedStoreService.getSharedAsset('currencies').pipe(
      map(currencies => {
        const userCurrency = JSON.parse(localStorage.getItem('currentUser'))?.currency;
        if (typeof userCurrency === 'number') {
          return currencies.find(c => c.id === userCurrency);
          // If the currentUser object in LocalStorage is not number, it means that the object is currency
        } else {
          return userCurrency;
        }
      }),
      catchError(err => {
        this.onHttpCompletionResolve(false, 'Failure',
          'Currencies fetch failed', '', '');
        return of(err);
      }),
    );
  }
  getPublisherIdFromLocalStorage() {
    return JSON.parse(localStorage.getItem('currentUser'))?.publisherId;
  }

  getPublisherNameFromLocalStorage(): string {
    return JSON.parse(localStorage.getItem('currentUser'))?.publisherName;
  }

  getPortalPermissionsFromLocalStorage() {
    return JSON.parse(localStorage.getItem('portalPermissions'));
  }

  getUserIdFromLocalStorage() {
    return JSON.parse(localStorage.getItem('currentUser'))?.userId;
  }

  convertDateToNumberYYYYMMDD(dateToConvert, hasHyphen: boolean = false) {
    if (!dateToConvert) {
      return 0;
    }
    const year = dateToConvert.getFullYear();
    const month = dateToConvert.getMonth() + 1;
    const day = dateToConvert.getDate();
    const hyphen = hasHyphen ? '-' : '';
    return '' + year + hyphen + (month < 10 ? '0' : '') + month + hyphen + (day < 10 ? '0' : '') + day;
  }

  convertDateToMMDDYYYY(dateToConvert: Date): string {
    const year = dateToConvert.getFullYear();
    const month = dateToConvert.getMonth() + 1;
    const day = dateToConvert.getDate();
    return '' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day + '-' + year;
  }

  getFileFromUrl(url: string, name?: string) {
    return this.api.getFileFromUrl(url);
  }

  getEpochTimeByTimezoneOffset(inputDate: any, offset: number) {
    const utc = inputDate.getTime() + (inputDate.getTimezoneOffset() * 60000);  // This converts to UTC 00:00
    return utc + (3600000 * offset);
  }

  camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  snakeToCamelCase = s => s.replace(/(_\w)/g, k => k[1].toUpperCase());

  camelCaseToWords = str => {
    const tmp = str.replace(/([A-Z])/g, ' $1');
    return tmp.charAt(0).toUpperCase() + tmp.slice(1);
  };

  // Validators
  changeEmptyToNull = (control: AbstractControl) => {
    if (control.value === '' || control.value === 0) {
      control.setValue('0.00');
    }
  };

  passwordValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const upperCaseRegex = '(?=.*[A-Z])';
    const lowerCaseRegex = '(?=.*[a-z])';
    const numberRegex = '(?=.*[0-9])';
    const specialCharacterRegex = '^(?=.*[\\W_])';

    let error = null;
    if (control.value !== null && control.value !== '') {
      if (!control.value.match(upperCaseRegex)) {
        error = {...error, 'upperCase': true};
      }

      if (!control.value.match(lowerCaseRegex)) {
        error = {...error, 'lowerCase': true};
      }

      if (!control.value.match(numberRegex)) {
        error = {...error, 'number': true};
      }

      if (!control.value.match(specialCharacterRegex)) {
        error = {...error, 'specialCharacter': true};
      }

      if (control.value.length < 8) {
        error = {'shortPassword': true};
      }

      if (control.value.length > 20) {
        error = {'longPassword': true};
      }
    }
    return error;
  };

  mediaTypeValidator = (control: AbstractControl): { [key: string]: any } | null => {
    let error = null;
    if (control) {
      const filterApp = control.get('filterApp').value;
      const filterSite = control.get('filterSite').value;

      if (!filterSite && !filterApp) {
        error = {'mediaTypeRequired': true};
        control.setErrors(error);
      }
    }
    return error;
  };

  datesValidator = (control: AbstractControl): { [key: string]: any } | null => {
    let endDateBeforeStartDateError = null;
    if (control) {
      const dealEndControl = control.get('dealEnd');
      const dealEnd = dealEndControl.value;
      const dealStart = control.get('dealStart').value;

      const todayEstDateTime = dayjs().tz('America/New_York').format('MM/DD/YYYY HH:mm');
      const isTodayLaterThanStartDate = dayjs(todayEstDateTime).isAfter(dealStart);
      const latestStartTodayDate = isTodayLaterThanStartDate ? todayEstDateTime : dealStart;

      if (dealStart && dealEnd) {
        if (!dayjs(dealStart).isBefore(dealEnd)) {
          endDateBeforeStartDateError = { datesValidate: 'Should be after "Start Date"' };
        }
        if (dayjs(dealEnd).diff(latestStartTodayDate, 'year', true) > 1) {
          endDateBeforeStartDateError = { datesValidate: `End date must be within 1 year of ${isTodayLaterThanStartDate ? 'today' : 'start date'}` };
        }
      }

      const existingEndDateErrors = dealEndControl.errors;
      const endDateControlError = (existingEndDateErrors || endDateBeforeStartDateError)
        ? {...existingEndDateErrors, ...endDateBeforeStartDateError}
        : null;

      dealEndControl.setErrors(endDateControlError);
    }
    return endDateBeforeStartDateError;
  };

  unrulyBundledDealValidator = (control: AbstractControl): { [key: string]: any } | null => {
    let error = null;
    if (control) {
      const dealPubdealBundles = control.get('dealPubdealBundles')?.value || [];
      const inventoryType = control.get('inventoryType')?.value;

      if (!dealPubdealBundles) {
        return null;
      }

      if (inventoryType === 'unruly_ctrl_deal' && dealPubdealBundles.length === 0) {
        error = {'unrulyBundledDeal': true};
        control.setErrors(error);
      }
    }
    return error;
  };

  sspDealListValidator = (control: AbstractControl): { [key: string]: any } | null => {
    let error = null;
    if (control) {
      const inventoryType = control.get('inventoryType')?.value;
      const selectedSsps = control.get('filterSspIds')?.value || [];
      const selectedSspDealIds = control.get('filterSspDealIds')?.value || [];

      if (inventoryType === 'ssp_deal' && selectedSsps.length === 0) {
        error = {'sspListError': true};
        control.setErrors(error);
        return error;
      }

      if (inventoryType === 'ssp_deal' && selectedSsps.length > 0 && selectedSspDealIds.length === 0) {
        error = {'sspDealListError': true};
        control.setErrors(error);
      }
    }
    return error;
  };

  videoAdSizeValidator = (control: AbstractControl): { [key: string]: any } | null => {
    let error = null;
    if (control) {
      const filterVideoSize = control.get('filterVideosize').value;
      const filterVideoSizeList = control.get('filterVideosizeList').value || [];

      if (!filterVideoSize && filterVideoSizeList.length > 0) {
        error = {'videoAdSizeError': true};
        control.setErrors(error);
      }
    }
    return error;
  };

  demandMarginValidator = (control: AbstractControl): { [key: string]: any } | null => {
    let error = null;
    if (control) {
      const demandMarginControl = control.get('demandMarginValue');
      const demandMarginsByPublisherControl = control.get('demandMarginsByPublisher') as UntypedFormArray;
      if (!demandMarginsByPublisherControl?.controls) {
        return null;
      }
      const publisherCounter =
        this.arrayObjectsCounter(demandMarginsByPublisherControl.controls.map((demandMarginByPublisherControl) =>
          demandMarginByPublisherControl.get('publisherId').value
        ));

      if (!Number.isFinite(demandMarginControl.value) && demandMarginsByPublisherControl?.value?.length > 0) {
        error = {DemandMarginEmptyWithDemandMarginsByPublisher: true};
        demandMarginControl.setErrors(error);
      }

      demandMarginsByPublisherControl.controls.forEach((demandMarginByPublisherControl) => {
        const publisherIdControl = demandMarginByPublisherControl.get('publisherId');
        if (publisherCounter[publisherIdControl.value] > 1) {
          publisherIdControl.setErrors({duplicatedPublisher: true});
        }
      });
    }
    return error;
  };

  dealSegmentGroupsValidator = (control: FormGroup): { [key: string]: any } | null => {
    let error = null;
    if (control) {
      const dealSegmentGroupsControl = control.controls?.dealSegmentGroups;

      if (dealSegmentGroupsControl?.value?.some((dealSegmentGroup) => dealSegmentGroup.segments?.length === 0)) {
        error = {'dealSegmentGroupsError': true};
        dealSegmentGroupsControl?.setErrors(error);
      }
    }
    return error;
  };

  passwordConfirmationValidator = (control: AbstractControl): { [key: string]: any } | null => {
    let error = null;
    if (control) {
      const pass = control.get('password');
      const conf = control.get('passwordConfirmation');
      if (pass.value !== conf.value && pass.dirty && conf.dirty) {
        error = {'notSamePassword': true};
        control.setErrors(error);
      }
    }
    return error;
  };

  // convert a simple string array ['a', 'b', 'c'] to object {'a': 1, 'b': 1, 'c': 1}
  arrayToObjectJsonDbFormat(arr: any[] = [], letterCase = ''): string {
    if (arr && arr.length > 0) {
      return JSON.stringify(arr.reduce(function (result, item, index, array) {
        if (null != item) {
          result[letterCase === 'lowercase' ? item.toLowerCase() : item] = 1;
        }
        return result;
      }, {}));
    } else {
      return null;
    }
  }

  objectJsonDbFormatToArray(obj: any): string[] {
    if (obj) {
      return Object.keys(JSON.parse(obj));
    } else {
      return null;
    }
  }

  updateFilterPossibleGroups(filters: AppFilter[], filterId: string, groups: GroupAppFilter[]): AppFilter[] {
    const localFilters: AppFilter[] = cloneDeep(filters);
    const updateFilterIndex: number = localFilters.findIndex(filter => filter.id === filterId);
    localFilters[updateFilterIndex].groups = cloneDeep(groups);
    localFilters[updateFilterIndex].isLoading = false;
    return localFilters;
  }

  noSlashValidator(control: AbstractControl): ValidationErrors | null {
    const forbidden = control.value?.includes('/');
    return forbidden ? {forbiddenCharacter: 'The character "/" is not allowed'} : null;
  }

  handleErrors(errorMessage: string, errorTitle = 'Failure'): Observable<never> {
    this.onHttpCompletionResolve(false, errorTitle, errorMessage,
      '', '');
    return EMPTY;
  }

  private fixDateByEstTimeZone(dates: [string, string]): [string, string] {
    const [start, end] = dates;
    const now = dayjs().format('HH:mm:ss');
    const estDayStart = dayjs(`${start} ${now}`).tz('America/New_York').format('YYYY-MM-DD');
    const estDayEnd = dayjs(`${end} ${now}`).tz('America/New_York').format('YYYY-MM-DD');
    return [
      dayjs(estDayStart).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      dayjs(estDayEnd).endOf('day').format('YYYY-MM-DDTHH:mm:ss')
    ];
  }

  private arrayObjectsCounter(array: any[]): Record<any, number> {
    return array.reduce(
      (acc, curr) => {
        if (curr) {
          acc[curr] = (acc[curr] || 0) + 1;
        }
        return acc;
      }, {});
  }
}
